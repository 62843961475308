import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from './Header';
import RoundLogo from '../assets/img/homeloader.png'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);

function Services() {
    const [sliderValue, setSliderValue] = useState(50);
    const imageUrls = [
        'https://m.media-amazon.com/images/G/31/img22/WLA/2024/Associate/D120448350_IN_WLA_BAU_Associate_graphics_2148x588_5.jpg',
        'https://m.media-amazon.com/images/G/31/FreshMarketing/Mar24SVD/2148x588_Static_1_Main.jpg',
        'https://m.media-amazon.com/images/G/31/Local/Associates/Room_Divider_Associates.jpg'
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setSliderValue((prevValue) => (prevValue + 1) % imageUrls.length);
        }, 6000); // Change image every 2 seconds (2000 milliseconds)

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []); // Run only once when component mounts
    return (<>
        <Header />
        <a target="_blank" href="https://www.amazon.in/b?_encoding=UTF8&tag=ssw0f5-21&linkCode=ur2&linkId=4189a096612479e8478f8ae9c5961e16&camp=3638&creative=24630&node=976419031">50 % off</a>
        <div id="spiral">
            <div class="scrolls">
                <img draggable="false" src={RoundLogo} style={{ height: "100px" }} alt="scroll" />
            </div>
            <div class="main-head">
                <div id="headmove">
                    <div data-depth="0.2">

                    </div>
                    <div data-depth="0.3">

                    </div>
                </div>
                <div class="heading-text">
                    <div class="heading-text-front">
                        <h1 class="title"></h1>
                    </div>
                    <div class="heading-text-back">
                        <h1 class="title"></h1>
                    </div>
                </div>
                <div class="text-container">
                    <span class="spanab">S</span>
                    <span class="spanab">E</span>
                    <span class="spanab">R</span>
                    <span class="spanab">V</span>
                    <span class="spanab">I</span>
                    <span class="spanab">C</span>
                    <span class="spanab">E</span>
                    <span class="spanab">S</span>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <p>Slider Value: {sliderValue}</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {imageUrls.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Image ${index + 1}`}
                            style={{
                                width: index === sliderValue ? 'auto' : 0, // Show current image only
                                height: 'auto',
                                marginRight: '10px',
                                transition: 'width 0.5s ease-in-out' // Add transition effect
                            }}
                        />
                    ))}
                </div>
            </div>
            {/* <!-- Start Content --> */}
            {/* <AutoplaySlider
                play={false}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
            >
                <div className='imgSlider' data-src="https://m.media-amazon.com/images/G/31/img22/WLA/2024/Associate/D120448350_IN_WLA_BAU_Associate_graphics_2148x588_5.jpg" />
                <div className='imgSlider' data-src="https://m.media-amazon.com/images/G/31/FreshMarketing/Mar24SVD/2148x588_Static_1_Main.jpg" />
                <div className='imgSlider' data-src="https://m.media-amazon.com/images/G/31/Local/Associates/Room_Divider_Associates.jpg" />
            </AutoplaySlider> */}
            <div class="col-md-12 text-center">
                <div class="text-head-slide">
                    <div class="text-slide">
                        <h2 class="text-1">WORDPRESS</h2>
                        <h2 class="text-2">WORDPRESS</h2>
                    </div>
                </div>
            </div>
            {/* <!-- End Content --> */}

            <div class="footer">
                <section class="folio-content section-footer">
                    <div class="container">

                    </div>
                </section>

                <div class="container">
                    <div class="row">

                        <div class="col-md-8 text-center mt-30">
                            <div class="logo-foot" >
                                <img src="<?php echo base_url('assets/img/logo2.png');?>" alt="" />
                            </div>
                            <h1 class="footerabc" >
                                <span class="color">S</span><span >anjay</span>
                                <span class="color">S</span><span>harma</span>
                                <span class="color">W</span><span>Orks</span></h1>
                        </div>
                        <div class="col-md-4 text-center text-md-right mt-30 footer-text-icon">
                            <div class="sosmed">
                                <div class="mail hover-target">
                                    <a href="tel:+918959697173" style={{ fontSize: "18px" }} >+91 8959 6971 73</a>
                                </div>
                                <div class="mail hover-target">
                                    <a href="" style={{ fontSize: "18px" }} >hello@sanjaysharmaworks.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copyright">
                        <p>Sanjay Sharma Works © 2021 All Rights Reserved Developed & Design <span style={{ color: "#000" }} >♥</span> by SSW.</p>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Services;