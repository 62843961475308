import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Logo from '../assets/img/logo2.png';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => {
    const navigator = useNavigate()
    const [menuHideShow, setMenuHideShow] = useState(false)
    useEffect(() => {

    }, []);


    return (<>
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div id='stars4'></div>
        <div className="preloader" style={{ display: "none" }}>
        </div>
        <div className="block-1" style={{ height: "0px" }}></div>
        <div className="block-2" style={{ height: "0px" }}></div>
        <div className="logo-load" style={{ visibility: "hidden", opacity: "0" }}>
            {/* <!-- <img src="./assets/img/loader.png" alt="" style="width: 50px;">  --> */}
        </div>
        <div className="logo-load spinning" style={{ visibility: "hidden", opacity: "0" }}></div>
        <div className="over-all" style={menuHideShow ? { visibility: "inherit", opacity: "1" } : { visibility: "hidden", opacity: "0" }} ></div>
        {/* <!-- End Preload-- > */}

        {/* < !--Start Header-- > */}
        <header>

            <nav>
                {/* <!-- Logo --> */}
                <div className="logo hover-target">
                    <a className="load-spiral" >
                        <img src={Logo} onClick={(e) => {
                            e.stopPropagation()
                            navigator('/')
                        }} className="logo-style"
                            alt="logo" /></a>
                </div>
                {/* <!-- Menu bar --> */}
                <div className="toggle-btn magnetic hover-target" id="main-button" onClick={(e) => {
                    e.stopPropagation()

                    if (menuHideShow) {
                        setMenuHideShow(false)
                    } else {
                        setMenuHideShow(true)

                    }
                }}>
                    <div className="burger-menu">
                        <span className="one"></span>
                        <span className="two"></span>
                        <span className="four" style={{ "display": "none" }} ><i className="fa fa-times" style={{ "color": "azure" }}
                            aria-hidden="true"></i></span>
                        <span className="tre"></span>
                    </div>
                </div>
                {/* <!-- Social --> */}
                <div className="scr socials">
                    <div className="socials">
                        <div className="list-social">
                            <ul>
                                <li className="hover-target"><a href=""><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                                <li className="hover-target"><a href=""><i className="fa fa-twitter-square footer-icon"
                                    aria-hidden="true"></i></a></li>
                                <li className="hover-target"><a href=""><i
                                    className="fa fa-instagram footer-icon" aria-hidden="true"></i></a></li>
                                <li className="hover-target"><a href=""><i
                                    className="fa fa-facebook-square footer-icon" aria-hidden="true"></i></a></li>

                            </ul>
                        </div>
                    </div>
                </div>

                {/* <!-- Copyright --> */}
                <div className="scr copyright-top">
                    <p>SSW. &copy; 2021</p>
                </div>

                <div className="bg-nav" style={menuHideShow ? { visibility: "inherit", opacity: "1" } : { visibility: "hidden", opacity: "0" }}></div>

                {/* <!-- menu --> */}
                <div className="manu-container">
                    <div className="menu" style={menuHideShow ? { visibility: "inherit", opacity: "1" } : { visibility: "hidden", opacity: "0" }}>
                        <div className="data">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="links text-center text-lg-left">
                                            <ul>
                                                <li>
                                                    <a className="menu-link hover-target load-spiral" onClick={(e) => {
                                                        e.stopPropagation()
                                                        navigator('/')
                                                    }} id="a1">Home
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="menu-link hover-target load-spiral" onClick={(e) => {
                                                        e.stopPropagation()
                                                        navigator('/about-us')
                                                    }} id="a2">About Us</a>
                                                </li>
                                                {/* <li>
                                                    <a className="menu-link hover-target load-spiral" onClick={(e) => {
                                                        e.stopPropagation()
                                                        navigator('/services')
                                                    }} id="a3">Services</a>
                                                </li>
                                                <li>
                                                    <a className="menu-link hover-target load-spiral" onClick={(e) => {
                                                        e.stopPropagation()
                                                        navigator('/contact-us')
                                                    }} id="a4">Contact</a>
                                                </li> */}

                                            </ul>

                                        </div>
                                    </div>
                                    {/* <!-- <div className="whovr"> 
                                            <img src="./assets/img/who-we-are.png" id="whovr">
                                        </div>
                                        <div className="futech">
                                            <img src="./assets/img/future-tech.png" id="futech">
                                        </div>
                                        <div className="proj">
                                            <img src="./assets/img/projects.png" id="proj">
                                        </div>
                                        <div className="hire">
                                            <img src="./assets/img/hire-us.png" id="hire">
                                        </div>
                                        <div className="press">
                                            <img src="./assets/img/press-story.png" id="press">
                                        </div>
                                        <div className="sayhi">
                                            <img src="./assets/img/say-hello.png" id="sayhi">
                                        </div> --> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>


        </header>
    </>)
}

export default Header;