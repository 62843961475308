import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from './Header';

function Contact() {
    return (<>
        <Header />

    </>)
}

export default Contact;