import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../components/Home';
import About from '../components/About';
import Services from '../components/Service';
import Contact from '../components/Contact';

function Routers() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={Home}></Route>
                <Route path="/about-us" Component={About}></Route>
                <Route path="/services" Component={Services}></Route>
                <Route path="/contact-us" Component={Contact}></Route>

            </Routes>
        </BrowserRouter>
    )
}

export default Routers;