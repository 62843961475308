import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from './Header';
import RoundLogo from '../assets/img/homeloader.png'
import AbountImage from '../assets/img/about-section-1.jpeg'



function About() {
    return (<>
        <Header />
        <div id="spiral">

            <div class="scrolls">
                <img draggable="false" src={RoundLogo} alt="scroll" style={{ height: "100px" }} />
            </div>
            <div class="main-head">

                <div class="heading-text">

                    <div id="headmove">
                        <div data-depth="0.2">

                        </div>
                        <div data-depth="0.3">

                        </div>
                    </div>
                    <div class="heading-text">
                        <div class="heading-text-front">
                            <h1 class="title"></h1>
                        </div>
                        <div class="heading-text-back">
                            <h1 class="title"></h1>
                        </div>
                    </div>
                    <div class="text-container">
                        <span class="spanab">A</span>
                        <span class="spanab">B</span>
                        <span class="spanab">O</span>
                        <span class="spanab">U</span>
                        <span class="spanab">T</span>
                        <span class="spanab">U</span>
                        <span class="spanab">S</span>
                    </div>
                </div>

            </div>
            {/* <!-- About --> */}
            <section class="about-page">
                <div class="container">
                    <div class="row centered">

                        <div class="col-lg-6">
                            <div class="about-text">
                                <h3> Take a step to build the future of your Brand with SSW</h3>
                                <p class="about-p"><span style={{ fontSize: "25px", "color": "#fff" }} >Sanjay Sharma Works</span>  is a trusted web design and web development agency in Indore, India offering a wide range of services to a global clientele. SSW started its journey as a website development company and as it progressed, adopted a best services approach to digital marketing that includes web designing, SEO, mobile app development, web application development, branding and video creation.
                                </p>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <img class="img-fluid" src={AbountImage} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="service">
                <div class="container">
                    <div class="row mt-30">
                        <div class="col-lg-6 mt-30">
                            <div class="service-item">
                                <div class="service-text">
                                    <article>
                                        <h1 id="headline">Our Vision</h1>
                                        <p id="text" class="about-p2">To become a global leader and reliable software solution company serving clients covering all major locations and time zones. We strive to keep up with the values and principles that make us your best choice.</p>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-30">
                            <div class="service-item">
                                <div class="service-text">
                                    <h3>Our Mission</h3>
                                    <p class="service-p">We aim to deliver cutting-edge technology solutions to a global clientele with honesty and transparency while accelerating business growth driven by research and continue learning and domain excellence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div class="footer">
                <section class="folio-content section-footer">
                    <div class="container">

                    </div>
                </section>

                <div class="container">
                    <div class="row">

                        <div class="col-md-8 text-center mt-30">
                            <div class="logo-foot" >
                                <img src="<?php echo base_url('assets/img/logo2.png');?>" alt="" />
                            </div>
                            <h1 class="footerabc" >
                                <span class="color">S</span><span >anjay</span>
                                <span class="color">S</span><span>harma</span>
                                <span class="color">W</span><span>Orks</span></h1>
                        </div>
                        <div class="col-md-4 text-center text-md-right mt-30 footer-text-icon">
                            <div class="sosmed">
                                <div class="mail hover-target">
                                    <a href="tel:+918959697173" style={{ fontSize: "18px" }} >+91 8959 6971 73</a>
                                </div>
                                <div class="mail hover-target">
                                    <a href="" style={{ fontSize: "18px" }} >hello@sanjaysharmaworks.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copyright">
                        <p>Sanjay Sharma Works © 2021 All Rights Reserved Developed & Design <span style={{ color: "#000" }} >♥</span> by SSW.</p>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default About;