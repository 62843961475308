import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import RoundLogo from '../assets/img/homeloader.png'
import AbountImage from '../assets/img/about.jpg'
import Header from './Header';
import Footer from './Footer';

function Home() {
    useEffect(() => {
        // Example usage of jQuery inside useEffect hook
        $('#cursor1').fadeOut();
        $('#cursor').fadeOut();


    }, []);
    return (<>
        <Header />
        <div id="spiral">

            {/* <!-- Header --> */}
            <div className="scrolls">
                <img draggable="false" src={RoundLogo} alt="scroll" style={{ height: "100px" }} />
            </div>
            <div className="main-head">
                <div id="headmove">
                    <div data-depth="0.2">
                        <div className="bg-right"></div>
                    </div>
                </div>
                <div className="heading-text">
                    <div className="heading-text-front">
                        <p >Say Hello. #SSW</p>
                        <h1>Sanjay Sharma Works.</h1>
                    </div>
                    <div className="heading-text-back">
                        <p>Say Hello. #SSW</p>
                        <h1>Sanjay Sharma Works.</h1>
                    </div>
                </div>
            </div>

            {/* <!-- About --> */}
            <section className="about">
                <div className="container">
                    <div className="row centered">
                        <div className="col-lg-7">
                            <div className="img-about luxy-el" data-horizontal="1" data-speed-x="1">
                                <img className="img-fluid" src={AbountImage} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="abt-text">
                                <p className="about-p">"Your Mind's eye Is Your Brand" Expand your Brand with SSW . ! We help you to develop your
                                    brand Development, Awareness, Trust and Loyalty. To Win huge attention
                                    your Brand, SSW To Observer Your Mind's idea. We're one-of-the visionary best Developer that forecasts your brand strategy with new Technologies,Web Development,Web Design,Digital Marketing, and
                                    Graphic Design.</p>
                                <a className="hover-target load-spiral" >About more</a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className="container home-contain">
                <article>
                    <h1 id="headline"></h1>
                    <p id="text" className="about-p2">We manage projects from beginning to end and work closely with our clients. We give shape to websites and provide solutions for our client business to succeed online. We never stop until you get the 100% satisfaction.</p>
                </article>
            </div>


            {/* <!-- type writer --> */}
            <div className="marquee-text">
                <svg width="100%" height="200" stroke="#ebf3f9" fill="#ebf3f9" className="tagline">
                    <text x="550" y="150" font-size="100" font-weight="bold">
                        <animate attributeType="XML" attributeName="x" values="50;-10000" dur="100s" repeatCount="indefinite" />
                        We-Unveil We-Assemble  We-Wizard We-Unveil  We-Assemble We-Wizard We-Unveil  We-Assemble We-Wizard  We-Unveil We-Assemble We-Wizard  We-Unveil We-Assemble We-Wizard
                    </text>
                </svg>
            </div>
            <section className="service">
                <div className="container">
                    <div className="row mt-30">
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>Web Development</h3>
                                    <p className="service-p">30+ Frameworks, 40+ Languages Cover in Custom Web DevelopmentCreative web solutions blended with latest technologies form the right base for new businesses.</p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>React js</li>
                                        <li>PHP</li>
                                        <li>Html | CSS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>Mobile App Development</h3>
                                    <p className="service-p">Custom mobile app solutions for iOS, Android and Windows by utilising latest technologies to solve unique and challenging business problems</p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>Flutter</li>
                                        <li>Android</li>
                                        <li>iOS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>UI/UX Design Development</h3>
                                    <p className="service-p">The look of a website or mobile app is as important as it’s functioning. We provide the soul to every project with blueprints that reflect originality.</p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>Wireframing</li>
                                        <li>UI/UX Design</li>
                                        <li>UX Strategy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>Digital Marketing</h3>
                                    <p className="service-p">SEO, SEM, SMM, SMO, PPC, CRO, ASO Stragety. </p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>Marketing</li>
                                        <li>Ranking</li>
                                        <li>Branding</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>E-Commerce Development</h3>
                                    <p className="service-p">Instant Ecommerce development Solution .</p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>WordPress</li>
                                        <li>Shopify</li>
                                        <li>Magento</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-30">
                            <div className="service-item">
                                <div className="service-text">
                                    <h3>Graphic Design</h3>
                                    <p className="service-p">Focused on collaborative approach, deliver business value..</p>
                                    <div className="service-line"></div>
                                    <ul className="service-list">
                                        <li>Logo</li>
                                        <li>Motion effect</li>
                                        <li>3D Animation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="footer">
                <section className="folio-content section-footer">
                    <div className="container">

                    </div>
                </section>

                <div className="container">
                    <div className="row">

                        <div className="col-md-8 text-center mt-30">
                            <div className="logo-foot" >
                                <img src="<?php echo base_url('assets/img/logo2.png');?>" alt="" />
                            </div>
                            <h1 className="footerabc">
                                <span className="color">S</span><span>anjay</span>
                                <span className="color">S</span><span>harma</span>
                                <span className="color">W</span><span>Orks</span></h1>
                        </div>
                        <div className="col-md-4 text-center text-md-right mt-30 footer-text-icon">
                            <div className="sosmed">
                                <div className="mail hover-target">
                                    <a href="tel:+919998987330" style={{ "font-size": "18px" }}>+91 8620 5149 04</a>
                                </div>
                                <div className="mail hover-target">
                                    <a href="" style={{ "font-size": "18px" }} >hello@sanjaysharmaworks.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>Sanjay Sharma Works © 2021 All Rights Reserved Developed & Design <span style={{ "color": "red" }}>♥</span> by SSW.</p>
                    </div>
                </div>
            </div>
        </div >

        <div className='cursor1' id="cursor1"></div>
        <div className='cursor' id="cursor"></div>
    </>)
}

export default Home;